import React, { Component } from "react";
import AdvertBox from "../../../components/advert/advert";
import "./brochure.css";
import { Link } from "react-router-dom";
export default class Brochure extends Component {
  componentDidMount() {
    document.title = "Warrant Calculator | Macquarie"
  }
  render() {
    return (
        <div id="brochure" className="pageobj">
          <div className="breadcrumbbar">
            <div className="container-fluid">
              <ol className="breadcrumb">
                <li>
                  <a href="/home/edu">Warrant education</a>
                </li>
                &nbsp;
                <li className="active">Brochure</li>
              </ol>
            </div>
          </div>
          <div className="stripe-bg"></div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <img src="/img/home/Infographic-jpeg-vers.jpg" alt="" style={{ width: '100%' }} />
                <BrochureSection2 />
              </div>
              <div className="col-md-4">
                <AdvertBox />
                <WarrantVideos />
                <InvestorAcademy />
              </div>
            </div>
          </div>
        </div>
    );
  }
}
class BrochureSection2 extends Component {
  render() {
    return (
        <div className="section" style={{ padding: '20px' }}>
          <h3 style={{ paddingTop: "20px", fontWeight: 'bold' }}>
            How to select a warrant to trade?
          </h3>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-xs-12 col-sm-6 col-md-6" style={{ padding: '0px' }}>
              <div className="section1" style={{ display: 'flex', border: '2px solid #29949E', flexDirection: 'column', borderTop: '0', padding: '0px 20px 20px 0px' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '22%', background: '#F0F0F0', paddingTop: '10px' }}>
                    <div style={{
                      background: '#29949E',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50px',
                      textAlign: 'center',
                      lineHeight: '50px',
                      fontSize: '28px',
                      margin: '0 auto',
                    }}>1</div>
                  </div>
                  <div style={{ background: '#F0F0F0', flex: '1' }}>
                    <p style={{
                      lineHeight: '40px',
                      fontSize: '17px',
                      // fontWeight: 'bold',
                      paddingTop: '10px'
                    }}><b style={{ fontWeight: 'bold' }}>View</b> on the underlying</p>
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', background: '#F0F0F0', justifyContent: 'space-around' }}>
                  <div style={{paddingLeft: '30px'}}><img src="/img/brochure/Entre_icon.png" alt="" style={{ width: '55px', height: "55px" }} /></div>
                  <div style={{ width: '65%' }}>
                    <div style={{ fontSize: '13px' }}>The price at which to</div>
                    <div style={{ fontSize: '13px' }}><b style={{ fontSize: '20px' }}>Enter  </b>and  <b style={{ fontSize: '20px' }}>Exit</b></div>
                    <div style={{ fontSize: '13px' }}>from a warrant is based on</div>
                    <div style={{ fontSize: '13px' }}><b style={{ fontSize: '20px' }}>Entry  </b>and  <b style={{ fontSize: '20px' }}>Exit</b></div>
                    <div style={{ fontSize: '13px' }}>levels in the underlying.</div>
                  </div>
                  <div style={{paddingRight: "15px"}}><img src="/img/brochure/Exit.png" alt="" style={{ width: '55px', height: "55px" }} /></div>
                </div>
              </div>
              <div style={{ paddingRight: '20px' }} className="section2">
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '22%', background: '#F0F0F0', paddingTop: '10px' }}>
                    <div style={{
                      background: '#29949E',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50px',
                      textAlign: 'center',
                      lineHeight: '50px',
                      fontSize: '28px',
                      margin: '0 auto'

                    }}>2</div>
                  </div>
                  <div style={{ background: '#F0F0F0', flex: '1' }}>
                    <p className="text_p" style={{
                      lineHeight: '40px',
                      fontSize: '16px',
                      paddingTop: '10px'
                    }}>Decide on your <b style={{ fontWeight: 'bold' }}>holding period</b></p>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ paddingRight: '20px', background: '#F0F0F0' }}>
                    <p style={{ fontSize: '10px', paddingLeft: '55px' }}>As with all leveraged products, warrants are not meant asa long-term investment.</p>
                    <div style={{ display: 'flex',
                      justifyContent: 'space-between',
                      // background: '#F0F0F0',
                      alignItems: 'center',
                      width: '80%',
                      background: '#29949E',
                      marginLeft: '10%', padding: '5px' }}>
                      <img src="/img/brochure/HSWT_2_Hourglass.png" alt="" style={{ width: '55px', height: "55px" }} />
                      <div style={{ fontSize: '10px', color: '#fff', paddingLeft: '5px' }}>
                        The holding costs for warrants is known as <b>time decay</b>, where a warrant’s price erodes with time.
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div className="padding_0" style={{ background: '#F0F0F0' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', background: '#F0F0F0', marginLeft: '10%', padding: '5px' }}>

                      <div style={{ fontSize: '10px', width: '55%' }}>
                        Use Macquarie’s <b style={{ color: '#29949e' }}>Warrant Selector</b> tool to help you <b style={{ color: '#29949e' }}>see the maximum holding period you should hold onto the warrant</b> based on your target underlying entry and exit levels,  <b style={{ color: '#29949e' }}>before</b> the warrant <b style={{ color: '#29949e' }}>gains are completely eroded by time decay</b>
                      </div>
                      <div style={{width: '120px'}}>
                        <div style={{marginTop: '50px'}}>
                          <Link to="/tools/selector" className='WarrantLink' style={{
                            fontSize: '12px',
                            background: '#004559',
                            color: '#fff',
                            // height: '23px',
                            lineHeight: '21px',
                            borderRadius: '5px',
                            // padding: '5px',
                            marginTop: '50px',
                            padding: '5px 5px 5px 5px',
                            textAlign: 'center',
                            width: '100%',
                            display: 'block'
                          }}>Warrant selector</Link>
                        </div>
                        <div style={{fontSize: '10px', marginTop: '5px', marginLeft: '9px'}}><b style={{fontWeight: 'bold'}}>Click here</b> to View</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ background: '#004559', width: '100%', display: 'flex', justifyContent: "space-between"  }}>

                    <div style={{ display: 'flex', padding: '5px', paddingLeft: '5px', flex: 1}}>
                      <img src="/img/brochure/HSWT_2_Tick.png" alt="" style={{ width: '30px', height: "30px" }} />
                      <div style={{ fontSize: '10px', color: '#fff', width: '80%', marginLeft: '5px' }}>
                        Choose an expiry longer than your intended holding period.
                      </div>
                    </div>
                    <div style={{ display: 'flex', padding: '5px', paddingLeft: '5px', flex: 1}}>
                      <img src="/img/brochure/Calendar%20icon.png" alt="" style={{ width: '30px', height: "30px" }} />
                      <div style={{ fontSize: '10px', color: '#fff', width: '80%', marginLeft: '5px' }}>
                        Generally, investors should avoid warrants expiring in the same month
                      </div>
                    </div>
                  </div>
                </div>


                <div style={{ display: 'flex' }}>
                  <div className="padding_0" style={{ background: '#F0F0F0' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', background: '#F0F0F0', marginLeft: '10%', padding: '5px' }}>

                      <div style={{ fontSize: '10px', width: '55%' }}>
                        Macquarie’s Warrant Calculator allows you to estimate the time decay on your intended holding period.
                      </div>
                      <div style={{width: '120px'}}>
                        <Link to="/tools/warrantcalculator" className="WarrantLink" style={{
                          fontSize: '12px',
                          background: '#004559',
                          color: '#fff',
                          // height: '23px',
                          lineHeight: '21px',
                          borderRadius: '5px',
                          // padding: '5px',
                          // marginTop: '10px',
                          padding: '5px 5px 5px 5px',
                          textAlign: 'center',
                          width: '100%',
                          display: 'block'
                        }}>Warrant calculator</Link>
                        <div style={{fontSize: '10px', marginTop: '5px', marginLeft: '9px'}}><b style={{fontWeight: 'bold'}}>Click here</b> to View</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6" style={{ padding: '0px' }}>
              <div className="section3" style={{ display: 'flex', border: '2px solid #29949E', borderLeft: '0', padding: '0px 0px 20px 0px' }}>
                <div style={{ width: '25%', background: '#F0F0F0', paddingTop: '10px', paddingLeft: '10px' }}>
                  <div style={{
                    background: '#29949E',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50px',
                    textAlign: 'center',
                    lineHeight: '50px',
                    fontSize: '28px',
                    margin: '0 auto',
                  }}>3</div>
                </div>
                <div style={{ background: '#F0F0F0' }}>
                  <p className="text_p" style={{
                    lineHeight: '40px',
                    fontSize: '17px',
                    // fontWeight: 'bold',
                    paddingTop: '10px',
                    paddingLeft: '10px'
                  }}>Determine your <b style={{ fontWeight: 'bold' }}>gearing level</b></p>
                  <p style={{ fontSize: '11px' }}>For example, a warrant with <b>8X effective gearing</b></p>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '-20px'}}>
                    <div>
                      <div className="font_div" style={{ fontSize: '25px', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ background: '#004559', color: '#fff', textAlign: 'center', paddingTop: '15px', fontFamily: "Arial, sans-serif"}}><b >1% <br/><div style={{fontSize: '10px', alignItems: 'center', padding: '5px'}}>move in underlying</div></b></div>
                        <div style={{ paddingTop: '20px', paddingLeft: '1px', paddingRight: '1px'}}>= </div>
                        <div style={{ background: '#004559', color: '#fff', textAlign: 'center', paddingTop: '15px', fontFamily: "Arial, sans-serif"}}><b >8% <br/><div style={{fontSize: '10px', alignItems: 'center', padding: '5px 10px 5px 10px'}}>move in  warrant</div></b></div>
                      </div>
                    </div>
                    <img src="/img/brochure/HSWT_3_Gearing.png" alt="" className={"HSWT_3_Gearing"} style={{ width: '65px', height: "55px", marginRight: '9px' }} />
                  </div>
                  <p style={{ fontSize: '11px', marginTop: '5px', marginLeft: '-20px' }}>
                    <b style={{ fontSize: '15px' }}>8X more</b> than the underlying percentage move However, the <b style={{ color: '#29949E' }}>higher the effective gearing </b>level in the warrant, the <b style={{ color: '#29949E' }}>riskier the warrant,</b> as gearing works both ways.
                  </p>
                  {/* <div style={{ marginTop: '10px', fontSize: '11px' }}><b style={{ fontSize: '15px' }}>8X more</b> than the underlying percentage move</div>
                <div style={{ fontSize: '11px', marginTop: '2px' }}>However, the <b style={{ color: '#29949E' }}>higher the effective gearing </b>level in the</div>
                <div style={{ fontSize: '11px', marginTop: '2px' }}>warrant, the <b style={{ color: '#29949E' }}>riskier the warrant,</b> as gearing works both way</div> */}
                </div>
              </div>


              <div style={{ borderLeft: '2px solid #29949E', background: '#F0F0F0', }} className="section4">
                <div style={{ display: 'flex', padding: '0px 0px 5px 0px' }}>
                  <div style={{ width: '20%', paddingTop: '10px' }}>
                    <div style={{
                      background: '#29949E',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50px',
                      textAlign: 'center',
                      lineHeight: '50px',
                      fontSize: '28px',
                      margin: '0 auto'
                    }}>4</div>
                  </div>
                  <div>
                    <p className="text_p margin_10" style={{
                      lineHeight: '40px',
                      fontSize: '17px',
                      paddingTop: '10px'
                    }}><b style={{fontWeight: 'bold'}}>Avoid</b> warrants on tight spreads</p>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ paddingRight: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '5%', padding: '5px' }}>

                      <div style={{ fontSize: '10px', width: '60%' }}>
                        Investors can <b style={{ color: ' #29949e' }}>see warrants</b> that are trading <b style={{ color: ' #29949e' }}>on tight spreads</b> on Macquarie’s Live Matrix.
                      </div>
                      <div>
                        <Link className="LiveMatrixLink" to="/tools/livematrix" style={{
                          fontSize: '15px',
                          background: '#004559',
                          color: '#fff',
                          height: '23px',
                          lineHeight: '14px',
                          borderRadius: '5px',
                          // padding: '5px',
                          marginTop: '10px',
                          padding: '5px 10px 5px 10px'
                        }}>Live Matrix</Link>
                        <div style={{padding: "0 0 5px"}}></div>
                        <div style={{fontSize: '10px', marginLeft: '9px'}}><b style={{fontWeight: 'bold'}}>Click here</b> to view</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', marginLeft: '5%', padding: '5px', background: 'rgb(41, 148, 158)', color: '#fff', width: '60%' }}>
                  <img src="/img/brochure/HSWT_4_Information.png" alt="" style={{ width: '30px', height: "30px" }} />
                  <div style={{
                    fontSize: '10px', flex: '1', lineHeight: '30px', fontWeight: 'bold',
                    paddingLeft: '5px'
                  }}>
                    What is a tight spread?
                  </div>
                </div>
                <div style={{ display: 'flex', padding: '5px', textAlign: 'center', alignItems: 'center', justifyContent: 'space-around' }}>
                  <div>
                    <div style={{ fontSize: '35px', color: '#29949E' }} className="fontDiv">$0.001</div>
                    <div style={{ fontWeight: 'bold' }}>spread</div>
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    Between
                    <br />
                    the <b>bid</b> and <b>
                    <br />
                    offer</b> price
                  </div>
                  <div>
                    <div style={{ fontSize: '11px' }}>For warrants priced</div>
                    <div style={{ fontSize: '35px', color: '#29949E' }} className="fontDiv">&lt;$0.20</div>
                  </div>
                </div>
                <hr style={{
                  height: '2px', background: '#2E96A0', width: '96%', marginTop: '6px',
                  marginBottom: '6px'
                }} />
                <div style={{ display: 'flex', padding: '5px', textAlign: 'center', alignItems: 'center', justifyContent: 'space-around' }}>
                  <div>
                    <div style={{ fontSize: '35px', color: '#29949E' }} className="fontDiv">$0.005</div>
                    <div style={{ fontWeight: 'bold' }}>spread</div>
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    Between
                    <br />
                    the <b>bid</b> and <b>
                    <br />
                    offer</b> price
                  </div>
                  <div>
                    <div style={{ fontSize: '11px' }}>For warrants priced</div>
                    <div style={{color: '#29949E', display: 'flex'}}>
                      <div style={{ fontSize: '35px', flex: 1}} className="fontDiv">$0.20 </div>
                      <div style={{flex:" 0 0 5px"}} ></div>
                      <div style={{paddingTop:'8px', flex: 1}}><img src="/img/brochure/up%20arrow%20icon.png" alt="" style={{ width: '30px', height: "30px" }} /></div>
                    </div>
                    <div style={{ fontSize: '11px' }}>and above</div>
                  </div>
                </div>
                <div style={{
                  width: '100%', height: '15px',
                }}></div>
                <div style={{
                  width: '100%', height: '15px',
                  background: '#fff'
                }}></div>
              </div>
            </div>
          </div>
          <div className="row"  >
            <div className="col-xs-12" style={{ padding: '0px' }}>
              <div style={{display: 'flex', background: '#004559', paddingBottom: '10px'}} className="section5_box">
                <div style={{width: '10%', paddingTop: '10px'}} className='section5_icon'>
                  <div style={{
                    background: '#29949E',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50px',
                    textAlign: 'center',
                    lineHeight: '50px',
                    fontSize: '28px',
                    margin: '0 auto',
                  }}>5
                  </div>
                </div>
                <div className={"hidden-md hidden-lg"} style={{display: "flex", flexDirection: "column"}}>
                  <div style={{width: '10%', paddingTop: '10px'}} className='section5_icon hidden-xs hidden-sm'>
                    <div style={{
                      background: '#29949E',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50px',
                      textAlign: 'center',
                      lineHeight: '50px',
                      fontSize: '28px',
                      margin: '0 auto',
                    }}>5
                    </div>
                  </div>
                  <p className="section5_text_p" style={{
                    lineHeight: '40px',
                    fontSize: '17px',
                    fontWeight: 'bold',
                    paddingTop: '20px',
                    color: '#fff'
                  }}>Quickest selection process</p>
                </div>

                <div className={"hidden-xs hidden-sm"} style={{display: "flex", flexDirection: "column"}}>
                  <div style={{width: '100%', color: '#fff'}} className="section5_text">
                    <p className="section5_text_p" style={{
                      lineHeight: '40px',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      paddingTop: '10px',
                      color: '#fff'
                    }}>Quickest selection process</p>
                    <div style={{display: 'flex', width: "100%"}}>
                      <div style={{width: '50%'}}><p style={{fontSize: '15px', color: '#fff'}}>For the quickest
                        selection process, visit Macquarie's <b>Warrant search</b> page.</p></div>
                      <div style={{marginLeft: '5px'}}>
                        <Link className="section5_link" to="/tools/warrantsearch" style={{
                          fontSize: '15px',
                          background: '#A3D4D9',
                          color: '#fff',
                          height: '23px',
                          lineHeight: '16px',
                          borderRadius: '5px',
                          padding: '5px 10px 5px 10px',
                          width: '20%',
                          textAlign: 'center',
                        }}>Warrant search</Link>
                        <div style={{padding: '0 0 10px'}}></div>
                        <div style={{fontSize: '12px', marginLeft: '9px'}}><b style={{fontWeight: 'bold'}}>Click
                          here</b> to View
                        </div>
                      </div>
                    </div>

                    <p style={{fontSize: '12px', color: '#fff'}}><b>Trending warrants</b></p>

                    <div style={{fontSize: '13px', display: 'flex', alignItems: 'center'}}>

                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className="border_width" style={{
                          background: '#29949E',
                          height: '40px',
                          width: '40px',
                          borderRadius: '50%',
                          textAlign: 'center',
                          lineHeight: '40px',
                          fontSize: '28px',
                          margin: '0 auto',
                        }}>
                          <img src="/img/brochure/HSWT_5_Liquidity.png" alt="" style={{width: '20px', height: "24px"}}/>
                        </div>
                        <span style={{padding: '5px'}}>High liquidity</span>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                        <div className="border_width" style={{
                          background: '#29949E',
                          height: '40px',
                          width: '40px',
                          borderRadius: '50%',
                          textAlign: 'center',
                          lineHeight: '40px',
                          fontSize: '28px',
                          margin: '0 auto',
                        }}>
                          <img src="/img/brochure/HSWT_5_TightSpread.png" alt=""
                               style={{width: '20px', height: "24px"}}/>
                        </div>
                        <span style={{padding: '5px'}}>Tight spread</span>
                      </div>
                    </div>
                  </div>

                  <div className="section5_icon1" style={{
                    // background: '#F0F0F0',
                    display: 'flex',
                    background: '#29949E',
                    width: '55%',
                    height: '58px',
                    marginTop: '10px',
                    // marginLeft: '5px',
                    alignItems: 'center',
                    marginRight: '20px',
                  }}>
                    <img src="/img/brochure/HSWT_5_LookOut.png" alt=""
                         style={{width: '55px', height: "55px", padding: '5px'}}/>
                    <div className="brochure_p" style={{
                      fontSize: '10px',
                      lineHeight: '12px',
                      color: '#085566',
                      paddingLeft: '5px',
                      wordBreak: 'break-word'
                    }}>
                      <span style={{fontWeight: 'bold', color: '#004559'}}>Look out </span>
                      <span style={{color: '#fff'}}>for the warrants with the</span>
                      <span style={{fontWeight: 'bold', color: '#004559'}}> fire icon </span>
                      <span style={{color: '#fff'}}>- indicating trending warrants</span>
                    </div>
                  </div>
                </div>
                <div className={"hidden-md hidden-lg"} style={{display: "flex", flexDirection: "column", marginLeft: "15px"}}>
                  <div style={{width: '100%', color: '#fff'}} className="section5_text">
                    <div style={{width: '100%'}}><p style={{fontSize: '13px', color: '#fff'}}>For the quickest
                      selection process, visit Macquarie's <b>Warrant search</b> page.</p></div>
                    <p style={{fontSize: '12px', color: '#fff'}}><b>Trending warrants</b></p>

                    <div style={{fontSize: '13px', display: 'flex', alignItems: 'center'}}>

                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className="border_width" style={{
                          background: '#29949E',
                          height: '40px',
                          width: '40px',
                          borderRadius: '50%',
                          textAlign: 'center',
                          lineHeight: '40px',
                          fontSize: '28px',
                          margin: '0 auto',
                        }}>
                          <img src="/img/brochure/HSWT_5_Liquidity.png" alt="" style={{width: '20px', height: "24px"}}/>
                        </div>
                        <span style={{padding: '5px'}}>High liquidity</span>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                        <div className="border_width" style={{
                          background: '#29949E',
                          height: '40px',
                          width: '40px',
                          borderRadius: '50%',
                          textAlign: 'center',
                          lineHeight: '40px',
                          fontSize: '28px',
                          margin: '0 auto',
                        }}>
                          <img src="/img/brochure/HSWT_5_TightSpread.png" alt=""
                               style={{width: '20px', height: "24px"}}/>
                        </div>
                        <span style={{padding: '5px'}}>Tight spread</span>
                      </div>
                    </div>
                  </div>
                  <div style={{display: 'flex', justifyContent: "space-between"}}>
                    <div style={{width: "40%", marginTop: "30px"}}>
                      <Link className="section5_link" to="/tools/warrantsearch" style={{
                        fontSize: '13px',
                        background: '#A3D4D9',
                        color: '#fff',
                        height: '23px',
                        lineHeight: '16px',
                        borderRadius: '5px',
                        padding: '5px 10px 5px 10px',
                        textAlign: 'center',
                      }}>Warrant search</Link>
                      <div style={{marginTop: '10px'}}></div>
                      <div style={{fontSize: '10px', marginLeft: '9px', color: 'white'}}><b style={{fontWeight: 'bold'}}>Click
                        here</b> to View
                      </div>
                    </div>

                    <div style={{width: "60%", marginTop: "10px"}}>
                      <div className="section5_icon1" style={{
                        flex: "1",
                        // background: '#F0F0F0',
                        display: 'flex',
                        background: '#29949E',
                        height: '58px',
                        // marginLeft: '5px',
                        alignItems: 'center',
                        marginRight: '10px',
                      }}>
                        <img src="/img/brochure/HSWT_5_LookOut.png" alt=""
                             style={{width: '55px', height: "55px", padding: '5px'}}/>
                        <div className="brochure_p" style={{
                          fontSize: '10px',
                          lineHeight: '12px',
                          color: '#085566',
                          paddingLeft: '5px',
                          wordBreak: 'break-word'
                        }}>
                          <span style={{fontWeight: 'bold', color: '#004559'}}>Look out </span>
                          <span style={{color: '#fff'}}>for the warrants with the</span>
                          <span style={{fontWeight: 'bold', color: '#004559'}}> fire icon </span>
                          <span style={{color: '#fff'}}>- indicating trending warrants</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

class BrochureSection extends Component {
  render() {
    return (
        <div className="section">
          <img
              src="/img/brochure/brochure1.jpg"
              style={{width: "100%"}}
              alt=""
          />
          <h1 style={{paddingTop: "20px"}}>
            Join us on Facebook and Telegram now and get the latest updates at
            your fingertips
          </h1>
          <div className="row" style={{paddingTop: "20px"}}>
            <div className="col-xs-6">
              <img
                  src="/img/brochure/brochure2.jpg"
                  style={{maxWidth: "100%"}}
                  alt=""
              />
            </div>
            <div className="col-xs-6">
              <ol>
                <li>point your camera at the QR codes</li>
                <li>tap on the pop-up message</li>
                <li>
                  “Like” our Facebook page and/or click Join on our Telegram page
                </li>
              </ol>
            </div>
          </div>
          <div className="page-header">
            <h1>What are Structured Warrants?</h1>
          </div>
          <p>
            Structured warrants are financial instruments issued by banks and
            financial institutions, and are listed on the securities market of the
            SGX. Investors, who are Specified Investment Products (SIP) Qualified,
            can trade structured warrants from their equity trading account.
            Structured warrants derive their value from an underlying asset,
            usually a share or index. Before investing in structured warrants, an
            investor should have a view on the underlying asset.
            <br/>
            <br/>
            Structured warrants are leveraged products, which generally move in
            greater percentages than price movements in the underlying asset, both
            positively and negatively. This is a key feature of structured
            warrants, Gearing.
          </p>
          <div className="page-header">
            <h1>For both bullish and bearish views</h1>
          </div>
          <div className="row">
            <div className="col-xs-6" style={{textAlign: "center"}}>
              <img
                  src="/img/brochure/brochure3.jpg"
                  style={{maxWidth: "100%"}}
                  alt=""
              />
              <p style={{fontWeight: "bold"}}>Call Warrant</p>
              <p>
                Generally increases in value if <br/>
                the{" "}
                <span style={{color: "#1F90CE"}}>
                underlying price increases
              </span>
              </p>
            </div>
            <div className="col-xs-6" style={{textAlign: "center"}}>
              <img
                  src="/img/brochure/brochure4.jpg"
                  style={{maxWidth: "100%"}}
                  alt=""
              />
              <p style={{fontWeight: "bold"}}>Put Warrant</p>
              <p>
                Generally increases in value if <br/>
                the{" "}
                <span style={{color: "#1F90C"}}>underlying price falls</span>
              </p>
            </div>
          </div>
          <p style={{fontWeight: "bold"}}>
            Key benefits of using structured warrants:
          </p>
          <ul>
            <li>
              potential to benefit from both positive and negative short-term
              views on the market
            </li>
            <li>
              designated market makers (DMM) for all SGX listed structured
              warrants. DMMs provide continuous bid and offer prices to enable
              investors to buy and sell in the market
            </li>
            <li>
              traded on the SGX like a stock – SIP qualified investors can trade
              structured warrants the same way they would trade stocks in their
              broker account.
            </li>
          </ul>
          <div className="page-header">
            <h1>How structured warrant prices move</h1>
          </div>
          <div style={{textAlign: "center"}}>
            <img
                src="/img/brochure/brochure5.jpg"
                style={{maxWidth: "100%"}}
                alt=""
            />
          </div>
          <p>
            In this example, a call warrant tracking the movements in ABC shares
            gained 30% for a 2% gain in the underlying ABC shares over the same
            period. The percentage gain derived from the call warrant is 15x more
            than the percentage move in ABC shares due to the gearing effect of
            warrants. Note that gearing works in both ways, and a 2% fall in ABC
            shares would mean a 30% drop in the price of the call warrant. The
            above example is hypothetical and used here for illustration purposes
            only.
          </p>
          <div className="page-header">
            <h1>Leveraged returns with Gearing</h1>
          </div>
          <img
              src="/img/brochure/brochure6.jpg"
              style={{ maxWidth: "80%" }}
              alt=""
          ></img>
          <ul>
            <li>
              Structured warrants move in greater percentages than the underlying
              they track, due to their gearing effect. Therefore, by investing in
              structured warrants, investors are able to generate
              leveraged/multiplied returns (both profits and losses)
              <ul style={{ listStyleType: "square" }}>
                <li>
                  For example, a warrant with an effective gearing of 20x will
                  move, both positively and negatively, approximately 20%, given a
                  1% move in the underlying
                </li>
              </ul>
            </li>
            <li>
              This also means that to generate the same amount of returns, the
              same warrant will require 20x less investment capital compared to
              the direct share investment
            </li>
            <li>
              While structured warrants provide leverage on the upside, the
              downside risk for warrant investors is capped at the initial
              investment as there are no margin calls on structured warrants
            </li>
          </ul>
          <div className="page-header">
            <h1>Live matrix</h1>
          </div>
          <p>
            The market maker’s bid and offer prices of the warrant are shown
            alongside a range of given prices of its underlying share or index on
            the Live Matrix tool.
            <br />
            The prices on the Live Matrix tool are provided on a real time basis
            for Macquarie warrants and can be accessed by all investors on the
            Macquarie Warrants website.
            <br />
            <br />
            With the Live Matrix, investors will be able to determine whether the
            market maker is providing a tight spread in a particular warrant, and
            also plan their entry and exit prices in the warrants, making their
            trading decisions easier.
          </p>
          <img
              src="/img/brochure/brochure7.jpg"
              style={{ maxWidth: "100%" }}
              alt=""
          />
          <div className="page-header">
            <h1>Costs to holding warrants</h1>
          </div>
          <p style={{ fontWeight: "bold" }}>Expiry date and holding costs:</p>
          <p>
            Warrants which are held beyond a day have a holding cost. For
            warrants, this cost is known as “time decay” or “theta”, where the
            value of the warrant reduces with time. This time decay speeds up as
            the warrant nears its expiry date.
          </p>
          <div className="page-header">
            <h1>Warrant selector</h1>
          </div>
          <div className="row">
            <div className="col-xs-6" style={{ textAlign: "center" }}>
              <img
                  src="/img/brochure/brochure8.jpg"
                  style={{ maxWidth: "100%" }}
                  alt=""
              />
            </div>
            <div className="col-xs-6" style={{ textAlign: "center" }}>
              Investors who wish to hold their warrants beyond a day can use the
              Warrant Calculator on Macquarie Warrants website to estimate how the
              price of the warrant will be impacted by theta over time, all other
              things being equal.
              <br />
              <br />
              Investors may also use the Warrant Calculator to estimate the
              returns that the warrant may generate according to price movements
              of the underlying asset by entering their target exit date and exit
              price of the underlying.
            </div>
          </div>
          <div className="page-header">
            <h1>Risk factors</h1>
          </div>
          <ul>
            <li>
              Gearing works in both ways, meaning one can gain or lose more in
              trading warrants than investing directly in the underlying asset.
            </li>
            <li>
              As with all investment products, warrants are subject to price
              movements in the underlying asset and other prevailing market
              fluctuations or circumstances. An investor may loss the full amount
              invested in the warrant.
            </li>
            <li>
              If held overnight, warrants are subjected to holding costs which
              increases the longer the holding period is. Manage holding costs by
              using the warrant calculator.
            </li>
            <li>
              Warrants with foreign underlying assets are subject to foreign
              exchange risk. The warrants trade and settle in SGD, and the value
              of the warrants will change in response to movements in the FX rate,
              as well as the underlying asset.
            </li>
          </ul>
          <div className="page-header">
            <h1>How to trade structured warrants?</h1>
          </div>
          <p>
            Structured warrants are considered Specified Investment Products (SIP)
            and you would need to be SIP qualified.
          </p>
          <p>
            <em>
              Check with your broker if you are SIP qualified and how to be
              eligible.
            </em>
          </p>
          <p style={{ fontWeight: "bold" }}>
            Listed on the SGX, structured warrants are traded like a stock
          </p>
          <ol>
            <li>
              Click on{" "}
              <span style={{ fontWeight: "bold" }}>“Trade warrants here”</span> to
              log into your stock trading account
            </li>
            <li>
              Key in the{" "}
              <span style={{ fontWeight: "bold" }}>
              4-character alphanumeric warrant code
            </span>{" "}
              to trade
            </li>
          </ol>
          <img
              src="/img/brochure/brochure9.jpg"
              style={{ maxWidth: "100%" }}
              alt=""
          />
          <h1
              style={{ fontWeight: "bold", color: "#1F90CE", paddingTop: "20px" }}
          >
            Important notice and disclaimer
          </h1>
          <p>
            This advertisement has not been reviewed by the Monetary Authority of
            Singapore.
            <br />
            <br />
            The information provided herein, is produced by Macquarie Capital
            Securities (Singapore) Pte. Limited (Registration No 198702912C)
            (“MCSSPL”), holder of a capital markets services licence under the
            Securities and Futures Act, Chapter 289 of Singapore.
            <br />
            <br />
            The information is directed and available for general circulation to
            residents of Singapore only.
            <br />
            Any material provided herein, including any indicative terms are
            provided for information purposes only and do not constitute an offer,
            a solicitation of an offer, or any advice or recommendation to
            conclude any transaction.
            <br />
            <br />
            Macquarie Bank Limited (ABN 46 008 583 542) (“MBL”) is the Issuer of
            the Macquarie Warrants (“Warrants”). References to Macquarie include
            MBL and MCSSPL. Any other parties distributing this product are only
            doing so as a distributor for MBL. MCSSPL will provide administrative
            services to MBL. MBL is regulated as an authorised deposit taking
            institution by the Australian Prudential Regulation Authority. MBL,
            acting through its Singapore branch is authorised and licensed by the
            Monetary Authority of Singapore to carry on wholesale banking business
            in Singapore pursuant to the Banking Act, Chapter 19 of Singapore and
            therefore is subject to the supervision of the Monetary Authority of
            Singapore. MCSSPL is not an authorised deposit taking institution for
            the purposes of the Banking Act 1959 (Commonwealth of Australia), and
            MCSSPL’s obligations do not represent deposits or other liabilities of
            MBL. MBL does not guarantee or otherwise provide assurance in respect
            of the obligations of MCSSPL. MBL may enter into discount, commission
            of fee arrangements with brokers, distributors and/or any of it’s
            affiliates with respect to the primary and secondary markets in the
            Warrants.
            <br />
            <br />
            Warrants can be volatile instruments and may be subject to
            considerable fluctuations in value. The price of Warrants may fall in
            value as rapidly as it may rise due to, including but not limited to,
            variations in the frequency and magnitude of the changes in the price
            of underlying share or index, dividends and interest rate, the time
            remaining to expiry and the creditworthiness of MBL. Therefore
            Warrants run the risk of expiring worthless resulting in a total loss
            of your investment. Where past performance is referred to, it is not
            indicative of future performance. In preparing the information
            contained herein, Macquarie did not take into account the investment
            objective, financial situation and particular needs of the reader.
            Before making an investment decision on the basis of the information
            contained herein, you should consult, to the extent necessary, your
            own independent, competent, legal, financial and other professional
            advisers, to ensure that any decision you make is suitable for you
            with regard to your investment needs, objectives and financial
            circumstances. MBL makes no representation nor can it give any
            assurance as to the liquidity in the trading of Warrants as MCSSPL,
            the Designated Market Maker, may be the only person quoting prices in
            the Warrants. In deciding whether to acquire or continue to hold an
            investment, you should obtain the base listing document and the
            relevant supplemental listing document from us and consider its
            content carefully before making any decision about this financial
            product. Copies of the listing documents may be obtained from the
            offices of MCSSPL at 9 Straits View #21-07 Marina One West Tower
            Singapore 018937 and on www.warrants.com.sg.
            <br />
            <br />
            Disclosures with respect to the listed companies, if any, mentioned in
            this document are available at www.macquarie.com/disclosures.
          </p>
        </div>
    );
  }
}

class WarrantVideos extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">School of Warrants</h2>
        </div>
        <p className="section-p">
          Improve your knowledge of warrant in these short 3 min videos.
        </p>
        <div className="section-qlink-img section-qlink-dwvideo">
          <a href="/education/marketcommentary/School%20of%20Warrants/2sWqTvnYqrA/0" className="section-qlink-achor">
            <img src="/img/bullet/qlink-achor.png" alt="" />
          </a>
        </div>
      </div>
    );
  }
}

class InvestorAcademy extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrants guidebook</h2>
        </div>
        <p className="section-p">Learn all about warrants here.</p>
        <div
          className="section-qlink-img section-qlink-investoracademy"
          style={{ minHeight: "155px" }}
        >
          <a
            target="_blank"
            href="/WarrantsGuidebook"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" alt="" />
          </a>
        </div>
      </div>
    );
  }
}
