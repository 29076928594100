import React, { Component } from "react";

import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import "./individualgallery.css";

let imgIndex=0,imgNext=true,imgPrev=true

export default class IndividualGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photogallerydata: null,
      photodata: null,
      photopopup: {},
      id: null,
      title: ""
    };
    this.initRedirect(props, this.state);
  }

  componentDidMount() {
    this.onFetchTitleData();
  }

  initRedirect(props, state) {
    const match = MQSGUtil.getData(props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const id = MQSGUtil.getData(params, "id", null);
        if (id !== null) {
          state["id"] = Number(id);
        }
      }
    }
  }

  onFetchTitleStaticData() {
    const staticData = MQSGUtil.getStaticData("IndividualGallery");
    if (staticData.photogalleryStaticData !== null) {
      this.setState({ photogallerydata: staticData.photogalleryStaticData });
      this.setupTitle(staticData.photogalleryStaticData);
      this.onFetchStaticData();
    }
  }

  onFetchTitleData() {
    if (MQSGUtil.isUsingStaticData("IndividualGallery")) {
      setTimeout(() => {
        this.onFetchTitleStaticData();
      }, 100); // pretend fetch
      return;
    }
    let photogallerydata = null;
    const url = MQSGUtil.getAPIBasePath() + "/PhotoGalleryJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          photogallerydata = result;
          this.setState({ photogallerydata });
          this.setupTitle(photogallerydata);
          this.onFetchData(this.state.id);
        },
        (error) => {
          this.setState({ photogallerydata });
        }
      );
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("IndividualGallery");
    if (staticData.albumID !== null && this.state.id !== null) {
      if (this.state.id in staticData.albumID) {
        this.setState({ photodata: staticData.albumID[this.state.id] });
      }
    }
  }

  onFetchData(id) {
    if (MQSGUtil.isUsingStaticData("IndividualGallery")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let photodata = null;
    const url = MQSGUtil.getAPIBasePath() + "/PhotoGalleryJSON?album_id=" + id;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          photodata = result;
          this.setState({ photodata });
        },
        (error) => {
          this.setState({ photodata });
        }
      );
  }

  setupTitle(photogallerydata) {
    let title = "";
    if (photogallerydata !== null && Array.isArray(photogallerydata)) {
      photogallerydata.forEach((gallerydata) => {
        const id = MQSGUtil.getData(gallerydata, "id");
        const entitle = MQSGUtil.getData(gallerydata, "en_title");
        if (id === this.state.id) {
          title = entitle;
        }
      });
    }
    this.setState({ title });
  }

  onImgClick(id,index) {
    const {photodata}=this.state
    const photopopupNew = { ...this.state.photopopup };
    photopopupNew[id] = true;
    this.setState({ photopopup: photopopupNew });
    // --add--
    imgIndex=index;
    imgPrev=true;
    imgNext=true;

    if(index==0){
      imgPrev=false;
      imgNext=true;
    }
    if(index==photodata.length-1){
      imgPrev=true;
      imgNext=false;
    }
  }
  onImageChgId(data){
    const photopopupNew = {};
    photopopupNew[data[0]] = true;
    this.setState({ photopopup: photopopupNew });
    imgIndex=data[1]; 
  }
  onImageClose(id) {
    const photopopupNew = { ...this.state.photopopup };
    photopopupNew[id] = false;
    this.setState({ photopopup: photopopupNew }); 
  }
  isShowImage(id) {
    if (id in this.state.photopopup) {
      return this.state.photopopup[id];
    }
    return false;
  }
  getImages() {
    const album = this.state.photodata;
    let albumColumns = null;
    if (album !== null && Array.isArray(album)) {
      albumColumns = album.map((photo, index) => {
        const id = MQSGUtil.getData(photo, "id");
        const key = "photo_" + index + "_" + id;
        const imgSrc = MQSGUtil.getAPIBasePath() + "/photo?id=" + id;
        const column = (
          <div className="col-xs-6 col-sm-3 photo-item" key={key}>
            <div className="photo-holder holder-center">
              <img src={imgSrc} onClick={() => this.onImgClick(id,index)} alt="" />
              <ImgShowBox
                show={this.isShowImage(id)}
                onImageClose={(id) => this.onImageClose(id)}
                onImageChgId={(data) => this.onImageChgId(data)}
                imgID={id}
                photodata={album}
              />
            </div>
          </div>
        );
        return column;
      });
    }
    return albumColumns;
  }

  render() {
    const columns = this.getImages();
    return (
      <div id="individualgallery" className="pageobj">
        <BreadcrumbBar
          link="/home"
          group="Market news"
          childlink="/marketnews/photogallery"
          child="Photo gallery"
          title={this.state.title}
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                <div className="page-header">
                  <h1>{this.state.title}</h1>
                </div>
                <div id="photos">
                  <div id="photo-list" className="row">
                    {columns}
                  </div>
                </div>
                <hr style={{ marginTop: "20px" }} className="hr-hmgbm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class ImgShowBox extends Component {
  onImageClose() {
    this.props.onImageClose(this.props.imgID);
  }
  onImagePrev(){
    const {photodata}=this.props
    imgIndex--;
    imgNext=true;
    if(imgIndex==0){
      imgPrev=false;
    }
    let data=photodata[imgIndex]['id'];
    this.props.onImageChgId([data,imgIndex]);
  }
  onImageNext(){
    const {photodata}=this.props
    imgIndex++;
    imgPrev=true;
    if(imgIndex==photodata.length-1){
      imgNext=false;
    }
    let data=photodata[imgIndex]['id'];
    this.props.onImageChgId([data,imgIndex]);
  }
  render() {
    const isShow = this.props.show ? {} : { display: "none" };
    const src = MQSGUtil.getAPIBasePath() + "/photo?id=" + this.props.imgID;
    return (
      <div id="popup-image" style={isShow}>
        <div>
          <div className="inner_area_con">
          <div className="inner-area">
            <img
              className="btn-close"
              src="/img/bullet/icon-close-white.png"
              onClick={() => this.onImageClose()}
              style={{
                background: "black"
              }}
              alt=""
            />
            <img
              className="image"
              src={src}
              style={{ maxHeight: "540px"}}
              alt=""
            />
            <div className="inner_area_btn">
              <span>
                <img
                  src="/img/marketnews/prev.png"
                  className="inner_area_img0"
                  style={{display:imgPrev?'':'none'}}
                  onClick={() => this.onImagePrev()}
                />
              </span>
              <span>
                <img
                  src="/img/marketnews/next.png"
                  className="inner_area_img1"
                  style={{display:imgNext?'':'none'}}
                  onClick={() => this.onImageNext()}
                />
              </span>
            </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}
