import React, { Component } from "react";
import { Link } from "react-router-dom";
import WarrantSelector from "../../tools/warrantselector/selector";
import LiveMatrix from "../../tools/livematrix/livematrix";
import WarrantSearch from "../../tools/warrantsearch/search";
import LiveIndexFuture from "../../../components/liveindexfuture/liveindexfuture";
import MQSGUtil from "../../../components/mqsgUtil";

class WarrantTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: { underlyingname: null, underlying_ticker: null },
      dwtooldata: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.onFetchData();
  }

  onFetchStaticData() {
    const isLoading = false;
    const staticData = MQSGUtil.getStaticData("WarrantTools");
    this.initUnderlyingname(staticData);
    this.setState({ dwtooldata: staticData, isLoading });
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("WarrantTools")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    const isLoading = false;
    let dwtooldata = null;
    const url = MQSGUtil.getAPIBasePath() + "/HomeJSON?m=dwtool";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          dwtooldata = result;
          this.initUnderlyingname(dwtooldata);
          this.setState({ dwtooldata, isLoading });
        },
        (error) => {
          this.setState({ dwtooldata, isLoading });
        }
      );
  }

  getTopmover() {
    if (this.state.dwtooldata !== null) {
      if ("topmover" in this.state.dwtooldata) {
        return this.state.dwtooldata.topmover;
      }
    }
    return null;
  }

  getTrendingWarrant() {
    if (this.state.dwtooldata !== null) {
      if ("trending_warrants" in this.state.dwtooldata) {
        return this.state.dwtooldata["trending_warrants"];
      }
    }
    return null;
  }

  onTopmoversChange(underlyingname, ticker) {
    const selectedNew = { ...this.state.selected };
    selectedNew["underlyingname"] = underlyingname;
    selectedNew["underlying_ticker"] = ticker;
    this.setState({ selected: selectedNew });
  }

  initUnderlyingname(dwtooldata) {
    if (this.state.selected.underlyingname === null) {
      let underlyingname = null;
      let underlying_ticker = null;
      if ("topmover" in dwtooldata) {
        if ("Indices" in dwtooldata.topmover) {
          const sgStocks = dwtooldata.topmover["Indices"];
          if (Array.isArray(sgStocks) && sgStocks.length > 0) {
            for (let index = 0; index < sgStocks.length; index++) {
              const stock = sgStocks[index];
              if ("underlying_name" in stock) {
                underlyingname = stock["underlying_name"];
                underlying_ticker = MQSGUtil.getUnderlyingTickerDisplayName(stock["underlying_ric"], stock["underlying_ticker"]);;
                break;
              }
            }
          }
        }
      }
      const selectedNew = { ...this.state.selected };
      selectedNew["underlyingname"] = underlyingname;
      selectedNew["underlying_ticker"] = underlying_ticker;
      this.setState({ selected: selectedNew });
    }
  }

  render() {
    return (
      <div id="dwtool" className="tabcontent">
        <div className="row">
          <div className="col-md-6">
            {/** top mover */}
            <div className="visible-sm visible-xs">
              <LiveIndexFuture />
            </div>

            {/* <div className="visible-sm visible-xs section">
              <LiveMatrix page="home" />
            </div> */}
            <div className="visible-sm visible-xs">
              <div id="warranttools" className="section">
                <div className="tools-row" style={{ marginTop: "40px" }}>
                  <div id="selector-tools" className="tools-detail">
                    <LiveMatrix page="home" />
                  </div>
                </div>
                <div className="tools-row" style={{ marginTop: "40px" }}>
                  <div id="selector-tools" className="tools-detail">
                    <WarrantSearch page="home" />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="visible-sm visible-xs section">
              <WarrantSearch page="home" />
            </div> */}
            <TopMovers
              underlyingname={this.state.selected.underlyingname}
              underlying_ticker={this.state.selected.underlying_ticker}
              topmover={this.getTopmover()}
              trendingwarrant={this.getTrendingWarrant()}
              onTopmoversChange={(underlyingname, ticker) =>
                this.onTopmoversChange(underlyingname, ticker)
              }
            />
            {/* WarrantsTool */}
            {/* <ToolColumns /> */}
            <div id="warranttools" className="section">
              <div className="tools-row">
                <div id="selector-tools" className="tools-detail">
                  <WarrantSearch page="home" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {/* index future*/}
            <div className="visible-md visible-lg">
              <LiveIndexFuture />
              <div id="warranttools" className="section">
                <div className="tools-row">
                  <div id="selector-tools" className="tools-detail">
                    <LiveMatrix page="home" />
                  </div>
                </div>
              </div>
            </div>
            {/* <Fb /> */}
            {/*quick Headline */}
            {/* <QuickHeadline/> */}
            {/* announcement*/}
            {/* <Announcement /> */}
            {/*seminar */}
            {/* <Seminar /> */}
          </div>
        </div>
      </div>
    );
  }
}

class TrendingWarrant extends Component {
  getData(data, fieldName) {
    if (data !== null) {
      if (fieldName in data) {
        return data[fieldName];
      }
    }
    return "";
  }

  getCallPutData(underlyingname, isCALL = true) {
    if (this.props.trendingwarrant !== null) {
      if (underlyingname in this.props.trendingwarrant) {
        const callputdata = this.props.trendingwarrant[underlyingname];
        if (isCALL && "CALL" in callputdata) {
          return callputdata["CALL"];
        } else if (!isCALL && "PUT" in callputdata) {
          return callputdata["PUT"];
        }
      }
    }
    return [];
  }

  getRowSubTable(rowdata) {
    const ticker = this.getData(rowdata, "ticker");
    const dsplyname = this.getData(rowdata, "dsply_name");
    const bid = this.getData(rowdata, "BID");
    const bidpctchng = this.getData(rowdata, "BID_PCTCHNG");
    let bidpctchngString = bidpctchng + "%";
    let bidpctchngupdown = "down";
    if (Number(bidpctchng) > 0) {
      bidpctchngString = "+" + bidpctchng + "%";
      bidpctchngupdown = "up";
    }
    const strike = this.getData(rowdata, "exercise_price");
    const expiry = this.getData(rowdata, "maturity");
    const eff = this.getData(rowdata, "effective_gearing");

    const row1 = (
      <tr>
        <td colSpan="2" className="tw_dsply_name">
          {dsplyname}
        </td>
      </tr>
    );
    const row2 = (
      <tr>
        <td style={{ width: "40%" }}>Price:</td>
        <td style={{ width: "60%" }}>
          {" "}
          $<span className="tw_BID">{bid}</span>&nbsp; (
          <span className={"tw_BID_PCTCHNG " + bidpctchngupdown}>
            {bidpctchngString}
          </span>
          )
        </td>
      </tr>
    );
    const row3 = (
      <tr>
        <td style={{ width: "40%" }}>Strike:</td>
        <td className="tw_strike" style={{ width: "60%" }}>
          {strike}
        </td>
      </tr>
    );
    const row4 = (
      <tr>
        <td style={{ width: "40%" }}>Expiry:</td>
        <td className="tw_expiry" style={{ width: "60%" }}>
          {expiry}
        </td>
      </tr>
    );
    const row5 = (
      <tr>
        <td style={{ width: "40%" }}>Eff gearing:</td>
        <td className="tw_eff" style={{ width: "60%" }}>
          {eff}
        </td>
      </tr>
    );
    const row6 = (
      <tr>
        <td colSpan="2">
          <div className="vmbtn-link" style={{ justifyContent: "normal" }}>
            <Link
              className="lmbtn"
              to={"/tools/livematrix/" + ticker}
              target="_blank"
            >
              Live
              <br />
              Matrix
            </Link>
            <a
              className="wtbtn"
              href={"/tools/warrantterms/" + ticker}
              style={{ marginLeft: "5px" }}
              target="_blank"
            >
              Warrant
              <br />
              Terms
            </a>
          </div>
        </td>
      </tr>
    );
    return (
      <table style={{ width: "100%" }}>
        <tbody>
          {row1}
          {row2}
          {row3}
          {row4}
          {row5}
          {row6}
        </tbody>
      </table>
    );
  }

  getRow(rowdata, index, isCALL = true) {
    let putcall = "PUT";
    let className = "put_tmp";
    if (isCALL) {
      putcall = "CALL";
      className = "call_tmp";
    }
    const ticker = this.getData(rowdata, "ticker");

    return (
      <tr className="homeTopMversTable" idx={index} key={putcall + "_" + index}>
        <td>
          <div className="tw_warrant">
            <div colSpan="2" className="tw_title" style={{ marginTop: '10px' }}>
              <div className="tw_type">{putcall}</div>
              <div className="tw_ticker">{ticker}</div>
            </div>
            {this.getRowSubTable(rowdata)}
          </div>
        </td>
      </tr>
    );
  }

  render() {
    const underlyingname = this.getData(this.props, "underlyingname");
    const calldata = this.getCallPutData(underlyingname);
    const callrows = [];
    calldata.forEach((rowdata, index) => {
      const row = this.getRow(rowdata, index);
      callrows.push(row);
    });
    const putdata = this.getCallPutData(underlyingname, false);
    const putrows = [];
    putdata.forEach((rowdata, index) => {
      const row = this.getRow(rowdata, index, false);
      putrows.push(row);
    });
    let callTableStyle = { display: "none" };
    let putTableStyle = { display: "none" };
    let noCallPutStyle = {
      display: "none",
      marginLeft: "-10px",
      marginRight: "-10px",
      backgroundColor: "rgb(245, 246, 246)",
    };
    let titleStyle = { display: "none" };
    if (callrows.length > 0) {
      callTableStyle = {};
    }
    if (putrows.length > 0) {
      putTableStyle = {};
    }
    if (callrows.length === 0 && putrows.length === 0) {
      noCallPutStyle = {
        backgroundColor: "rgb(245, 246, 246)",
      };
    }
    if (
      this.props.type === "HK_stocks" ||
      this.props.underlyingname === "S&P500"
    ) {
      titleStyle = { display: "inline-block" };
    }
    return (
      <div>
        <div
          style={{
            backgroundColor: "rgb(245, 246, 246)",
            marginLeft: "-10px",
            marginRight: "-10px",
            paddingLeft: "10px",
          }}
        >
          <span
            className="tw-name"
            style={{ fontWeight: "bold", fontSize: "18px" }}
          >
            {this.props.underlying_ticker}
          </span>
          <span style={{ fontSize: "15px", marginLeft: "5px" }}>
            trending warrant
          </span>
          <em style={{ fontSize: "12px", marginLeft: "5px", ...titleStyle }}>
            Price on 15 minute delay
          </em>
        </div>
        <div className="row tw_table" style={{ overflowY: 'auto', marginRight: '-10px', marginLeft: '-10px', marginTop: '0px' }}>
          <div className="call_table col-xs-6" style={callTableStyle}>
            <table className="tw_call_table" style={{ width: "100%" }}>
              <tbody>{callrows}</tbody>
            </table>
            <table className="tmp_table" style={{ display: "none" }}>
              <tbody>{/* insert point */}</tbody>
            </table>
          </div>
          <div className="put_table col-xs-6" style={putTableStyle}>
            <table className="tw_put_table" style={{ width: "100%" }}>
              <tbody>{putrows}</tbody>
            </table>
            <table className="tmp_table" style={{ display: "none" }}>
              <tbody></tbody>
            </table>
          </div>
          <p className="notw" style={noCallPutStyle}>
            No warrants available
          </p>
        </div>
      </div>
    );
  }
}

class TopMovers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uname: "",
      type: "",
      ric_name: '',
      showTable: 2,
    };
  }
  getData(data, fieldName) {
    if (data !== null) {
      if (fieldName in data) {
        return data[fieldName];
      }
    }
    return "";
  }
  getTopmover(type) {
    if (this.props.topmover !== null) {
      if (type in this.props.topmover) {
        return this.props.topmover[type];
      }
    }
    return [];
  }
  getTopmoverColumn(type) {
    const rows = [];
    const stocks = this.getTopmover(type);
    let typeClassName = "indices";
    let currency = "";
    let dollorsign = "";
    if (type === "HK_stocks") {
      typeClassName = "sgstock";
      currency = "HK$";
      dollorsign = "$";
    } else if (type === "SG_stocks") {
      typeClassName = "hkstock";
      currency = "S$";
      dollorsign = "$";
    }
    stocks.forEach((stock, index) => {
      const underlying = this.getData(stock, "underlying_ric");
      const ticker = this.getData(stock, "underlying_ticker");
      const underying = MQSGUtil.getUnderlyingTickerDisplayName(underlying, ticker);
      const underlyingname = this.getData(stock, "underlying_name");
      // const symbol = MQSGUtil.getUnderlyingTickerDisplayName(underlyingname);
      const underlyingticker = this.getData(stock, "underlying_ticker");
      const trdprc1 =
        currency + Number(this.getData(stock, "TRDPRC_1")).toFixed(2);
      const netchng1 = this.getData(stock, "NETCHNG_1");
      const netchng1String =
        netchng1 >= 0
          ? "+" + dollorsign + Number(netchng1).toFixed(2)
          : "-" + dollorsign + Number(Math.abs(netchng1)).toFixed(2);
      const updown = netchng1 >= 0 ? "up" : "down";
      const pctchng = this.getData(stock, "PCTCHNG");
      const pctchngString =
        pctchng > 0
          ? "+" + Number(pctchng).toFixed(2) + "%"
          : Number(pctchng).toFixed(2) + "%";
      const row = (
        <div className="hotlist-col" key={index}>
          <div
            data-index={index}
            className={"hotlist-item index-item index-item-" + index}
            style={{
              background: this.state.uname === underlyingname ? "#CEE5F5" : "",
            }}
          >
            <div
              className="hotlist-item-dataarea"
              name={underlying}
              onClick={() => this.onTopmoversChange(underlyingname, type, underying)}
            >
              <div className="hotlist-item-nm">
                {underying}
                <span
                  style={{ display: underying === "S&P500" ? "contents" : "none" }}
                >
                  <br />
                  <em style={{ fontSize: "12px" }}>Price on 15minute delay</em>
                </span>
              </div>
              <div className="hotlist-item-data">
                <div className={"hotlist-item-trend " + updown}></div>
                <div className={"hotlist-item-pc " + updown}>
                  {pctchngString}
                </div>
              </div>
              <div className="hotlist-item-change">
                <span className="hotlist-item-TRDPRC_1">{trdprc1}</span>(
                <span className={"hotlist-item-nc " + updown}>
                  {netchng1String}
                </span>
                )
              </div>
            </div>
            <div className="vmbtn-link">
              <a
                className="vmbtn"
                href={"/tools/selector/" + underlyingticker}
                target="_blank"
              >
                Warrant
                <br />
                selector
              </a>
              <Link
                className="vabtn"
                to={"/tools/warrantsearch/" + underlyingticker}
                target="_blank"
              >
                View all
                <br />
                warrants
              </Link>
            </div>
          </div>
        </div>
      );
      rows.push(row);
    });
    const column = (
      <div className="hotlist-right col-md-4">
        <div className={"hotlist-row first " + typeClassName}>{rows}</div>
      </div>
    );
    return column;
  }

  onTopmoversChange(underlyingname, type, ric_name) {
    this.setState({ uname: underlyingname, ric_name, type });
    this.props.onTopmoversChange(underlyingname, ric_name);
  }
  componentWillReceiveProps(props) {
    this.setState({ uname: props.underlyingname });
  }
  changeTable(v) {
    this.setState({ showTable: v });
  }
  render() {
    return (
      <div className="holder-topmover" style={{ position: "relative" }}>
        <div id="topmover" className="section">
          <div className="page-header" style={{ alignItems: 'center' }}>
            <h2 className="small">Top movers</h2>
            <a
              id="viewall"
              type="button"
              target="_blank"
              className="btn btn-primary viewallBtn"
              href="/marketdata/topgainloss"
              style={{ height: 35 }}
            >
              View All
            </a>
          </div>
          <div
            className="hotlist-row row"
            style={{
              paddingRight: "15px",
              backgroundColor: "#DFDBCF",
            }}
          >
            <div className="hotlist-right col-md-4 hidden-sm hidden-xs">
              <div className="hotlist-col-h">SG stock</div>
            </div>
            <div className="hotlist-right col-md-4 hidden-sm hidden-xs">
              <div className="hotlist-col-h">
                HK stock <br />
                <span style={{ fontSize: "12px" }}>
                  Price on 15minute delay
                </span>
              </div>
            </div>
            <div className="hotlist-right col-md-4 hidden-sm hidden-xs">
              <div className="hotlist-col-h">Indices</div>
            </div>
          </div>
          <div
            style={{
              marginBottom: "20px",
              maxHeight: "300px",
              overflowY: "scroll",
            }}
            className="hotlist-row row  hidden-sm hidden-xs"
          >
            {this.getTopmoverColumn("SG_stocks")}
            {this.getTopmoverColumn("HK_stocks")}
            {this.getTopmoverColumn("Indices")}
          </div>
          <div className="visible-xs visible-sm">
            <div
              className="hotlist-row row"
              style={{ paddingRight: "15px", backgroundColor: "#DFDBCF" }}
            >
              <div
                onClick={() => {
                  this.changeTable(0);
                }}
                className={`hotlist-right 
              hottab
             col-xs-4
             ${this.state.showTable === 0 ? "active" : ""}`}
              >
                <div className="hotlist-col-h">SG stock</div>
              </div>

              <div
                onClick={() => {
                  this.changeTable(1);
                }}
                className={`hotlist-right 
                  hottab
                 col-xs-4
                 ${this.state.showTable === 1 ? "active" : ""}`}
              >
                <div className="hotlist-col-h">HK stock</div>
              </div>

              <div
                onClick={() => {
                  this.changeTable(2);
                }}
                className={`hotlist-right 
                hottab
               col-xs-4
               ${this.state.showTable === 2 ? "active" : ""}`}
              >
                <div className="hotlist-col-h">Indices</div>
              </div>
            </div>
            <div
              style={{
                marginBottom: "20px",
                maxHeight: "300px",
                overflowY: "scroll",
              }}
              // className="hotlist-row row ulist"
              className={`
                hotlist-row
                row
                ulist
                ${this.state.showTable === 0 ? "active" : ""}`}
            >
              {this.getTopmoverColumn("SG_stocks")}
            </div>
            {/* <div
              className="hotlist-row row"
              style={{ paddingRight: "15px", backgroundColor: "#DFDBCF" }}
            >
              <div className="hotlist-right col-xs-4">
                <div className="hotlist-col-h">HK stock</div>
              </div>
            </div> */}
            <div
              style={{
                marginBottom: "20px",
                maxHeight: "300px",
                overflowY: "scroll",
              }}
              // className="hotlist-row row ulist "
              className={`
                hotlist-row
                row
                ulist
                ${this.state.showTable === 1 ? "active" : ""}`}
            >
              {this.getTopmoverColumn("HK_stocks")}
            </div>
            {/* <div
              className="hotlist-row row"
              style={{ paddingRight: "15px", backgroundColor: "#DFDBCF" }}
            >
              <div className="hotlist-right col-xs-4">
                <div className="hotlist-col-h">Indices</div>
              </div>
            </div> */}
            <div
              style={{
                marginBottom: "20px",
                maxHeight: "300px",
                overflowY: "scroll",
              }}
              // className="hotlist-row row ulist"
              className={`
                hotlist-row
                row
                ulist
                ${this.state.showTable === 2 ? "active" : ""}`}
            >
              {this.getTopmoverColumn("Indices")}
            </div>
          </div>
          <TrendingWarrant
            underlyingname={this.props.underlyingname}
            underlying_ticker={this.props.underlying_ticker}
            trendingwarrant={this.props.trendingwarrant}
            type={this.state.type}
            ric={this.state.ric_name}
          />
        </div>
      </div>
    );
  }
}

//弹出框
class SubscribeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  onSubscribeChange(isClose = false) {
    if (isClose) {
      this.props.onSubscribeChange(null);
    } else if (this.validateEmail()) {
      this.props.onSubscribeChange(this.state.email);
      this.setState({ email: "" });
    }
  }

  onEmailChange(event) {
    // need validation ?
    this.setState({ email: event.target.value });
  }

  validateEmail() {
    return true;
  }

  render() {
    const isShow = this.props.show ? {} : { display: "none" };
    return (
      <div id="ppboxHTML2" className="overlay" style={isShow}>
        <div className="overlay-inner">
          <div style={{ position: "relative" }}>
            <img
              className="btn-close"
              src=" /img/bullet/icon-close-black.png"
              onClick={() => this.onSubscribeChange(true)}
              alt=""
            />
            <div className="popupbox-content">
              <div className="ppc" key="subscribe-newsletter">
                <div className="signup-layer">
                  <div className="page-header">
                    <h3>Sign up now</h3>
                  </div>
                  <input
                    type="text"
                    id="email"
                    placeholder="Email address"
                    value={this.state.email}
                    onChange={(event) => this.onEmailChange(event)}
                  />
                  <div className="warning"></div>
                  <p className="small">
                    By clicking “Submit”, you confirm that you would like to
                    receive Macquarie’s daily newsletter and invitations for
                    warrant seminars, and you acknowledge that Macquarie’s
                    website administrators may have access to your personal data
                    for the processing of such data on behalf of Macquarie
                    Capital Securities (Singapore) Pte Limited and other
                    companies of Macquarie group (“Macquarie”).
                    <br />
                    <br />
                    Please read the{" "}
                    <a target="_blank" href="/about/privacypolicy">
                      privacy policy
                    </a>{" "}
                    and{" "}
                    <a target="_blank" href="/about/importantinformation">
                      important information
                    </a>{" "}
                    via the links at the bottom of this page to understand how
                    your personal information will be treated by Macquarie.
                  </p>
                  <input
                    className="btn btn-primary"
                    type="button"
                    id="submitBtn"
                    value="Submit"
                    onClick={() => this.onSubscribeChange()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="text-content" style={{ display: "none" }}>
          <div className="text_subscribe_registration_success">
            You have successfully registered for our daily newsletter!
          </div>
        </div>
      </div>
    );
  }
}

class QuickHeadline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickheadlinedata: null,
      disclaimerShow: false,
    };
  }

  componentDidMount() {
    this.onFetchData();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("QuickHeadline");
    if (staticData !== null) {
      this.setState({ quickheadlinedata: staticData });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("QuickHeadline")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let quickheadlinedata = null;
    const url = MQSGUtil.getAPIBasePath() + "/HomeJSON?m=dwnews";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          quickheadlinedata = result;
          this.setState({ quickheadlinedata });
        },
        (error) => {
          this.setState({ quickheadlinedata });
        }
      );
  }

  onSubscribeChange(email) {
    this.setState({ disclaimerShow: false });
  }

  onSubscribeClick() {
    this.setState({ disclaimerShow: true });
  }

  getheadline(type) {
    const headline = { title: "", id: "" };
    if (this.state.quickheadlinedata !== null) {
      const headlinedata = MQSGUtil.getData(
        this.state.quickheadlinedata,
        type,
        null
      );
      if (headlinedata !== null) {
        if ("en_title" in headlinedata)
          headline["title"] = headlinedata["en_title"];
        if ("id" in headlinedata) headline["id"] = headlinedata["id"];
      }
    }
    return headline;
  }

  render() {
    const highlight = this.getheadline("highlight");
    const overnight = this.getheadline("overnight");
    const highlightTitle = { __html: MQSGUtil.getData(highlight, "title") };
    const overnightTitle = { __html: MQSGUtil.getData(overnight, "title") };
    return (
      <div className="holder-quickHeadlines">
        <div id="quickHeadlines" className="section">
          <div className="page-header">
            <h1 className="small">Quick headlines</h1>
          </div>
          <div id="topTbl" className="calldw">
            <table className="table-striped table-responsive">
              <tbody>
                <tr className="rowDailyhighlight bgcolor-08">
                  <td className="news bgcolor-03"> Morning Market Buzz</td>
                  <td
                    className="newsTitle"
                    dangerouslySetInnerHTML={highlightTitle}
                  ></td>
                  <td className="detail">
                    <Link
                      to={
                        "/marketnews/highlight?cat=todayhighlight&id=" +
                        highlight.id
                      }
                    >
                      <img src=" /img/bullet/qlink-achor.png" alt="" />
                    </Link>
                  </td>
                </tr>
                <tr className="rowOvernight">
                  <td className="news bgcolor-03">Overnight market wrap</td>
                  <td
                    className="newsTitle"
                    dangerouslySetInnerHTML={overnightTitle}
                  ></td>
                  <td className="detail">
                    <Link
                      to={
                        "/marketnews/highlight?cat=overnightmarkets&id" +
                        overnight.id
                      }
                    >
                      <img src=" /img/bullet/qlink-achor.png" alt="" />
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="section-btn-wapper section-center">
            <p className="section-txt">
              Sign up for our daily newsletter for the latest updates on the
              market
            </p>
            <button
              className="ppbtn btn btn-primary"
              key="subscribe-newsletter"
              style={{ minWidth: "190px" }}
              type="button"
              onClick={() => this.onSubscribeClick()}
            >
              Subscribe
            </button>
            <SubscribeDialog
              show={this.state.disclaimerShow}
              onSubscribeChange={(email) => this.onSubscribeChange(email)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const announcementList = [
  {
    head: "All physical warrant seminars cancelled due to COVID-19",
    date: "February 26, 2020",
    text: "Note that all physical warrant education seminars have been cancelled due to COVID-19. This includes tomorrow's seminar. We are however, working on exciting webinars on warrant education, as well as to share trading ideas. We look forward to sharing them on our Facebook and Telegram pages!",
  },
];
class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readShow: false,
      itemData: {},
    };
  }
  onReadChange() {
    this.setState({ readShow: false });
  }

  onReadClick(index) {
    this.setState({
      readShow: true,
      itemData: announcementList[index],
    });
  }

  render() {
    return (
      <div className="holder-announcement">
        <div id="announcement">
          <div className="section announcement">
            <div className="page-header">
              <h2 className="small">Warrant announcements</h2>
            </div>
            <div className="announcement-list">
              <div className="row">
                <div className="announcement-col  col-xs-12 col-md-12">
                  {announcementList.map((item, index) => {
                    const text = { __html: MQSGUtil.getData(item, "text") };
                    return (
                      <div className="media general" key={index}>
                        <div className="media-left">
                          <img
                            className="media-object"
                            src=" /img/bullet/ann_general.png"
                            alt="general announcement"
                          />
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">
                            <span>{item.head}</span>
                            <br />
                            <span className="date">{item.date}</span>
                          </h4>
                          <p>{item.text}</p>
                          <p className="media-readmore">
                            <span
                              className="general-readmore"
                              value="0"
                              onClick={this.onReadClick.bind(this, index)}
                            >
                              Read more
                            </span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                  <ReadMore
                    show={this.state.readShow}
                    onReadChange={this.onReadChange.bind(this)}
                    data={this.state.itemData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ReadMore extends Component {
  onReadChange(isRead) {
    this.props.onReadChange(isRead);
  }

  render() {
    const isShow = this.props.show ? {} : { display: "none" };
    const obj = this.props.data;
    return (
      <div id="ppboxHTML" className="overlay" style={isShow}>
        <div className="overlay-inner">
          <div style={{ position: "relative" }}>
            <img
              className="btn-close"
              src=" /img/bullet/icon-close-black.png"
              onClick={this.onReadChange.bind(this, false)}
              alt=""
            />
            <div className="popupbox-content">
              <div
                className="ppc"
                key="Why-trade-index-warrants"
                id="announcement0"
              >
                <h2 className="ppc-h">{obj.head}</h2>
                {obj.text}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Seminar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seminardata: null,
    };
  }

  componentDidMount() {
    this.onFetchData();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("Seminar");
    if (staticData !== null) {
      this.setState({ seminardata: staticData });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("Seminar")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let seminardata = null;
    const url = MQSGUtil.getAPIBasePath() + "/HomeJSON?m=dwedu";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          seminardata = result;
          this.setState({ seminardata });
        },
        (error) => {
          this.setState({ seminardata });
        }
      );
  }

  getRows() {
    const rows = [];
    const seminars = MQSGUtil.getData(this.state.seminardata, "seminar", null);

    if (seminars !== null && Array.isArray(seminars)) {
      seminars.forEach((seminar, index) => {
        let originDate = MQSGUtil.getData(seminar, "date")
        if (originDate.endsWith("-")) {
          originDate = originDate.slice(0, -1)
        }
        const date = originDate;
        const time = MQSGUtil.getData(seminar, "en_time");
        const title = MQSGUtil.getData(seminar, "en_title", "sign up now");
        const id = MQSGUtil.getData(seminar, "id");

        const column1 = <td className="date">{date}</td>;
        const column2 = <td className="time">{time}</td>;
        const column3 = (
          <td className="title">
            <a href={"/education/seminar?seminar=" + id}>{title}</a>
          </td>
        );
        const row = (
          <tr className="seminartmpl" idx={index} key={index}>
            {column1}
            {column2}
            {column3}
          </tr>
        );
        rows.push(row);
      });
    }
    return rows;
  }

  render() {
    var rows = this.getRows();
    if (rows.length === 0) {
      rows = (
        <tr>
          <td colSpan="3" style={{ lineHeight: "45px", textAlign: "center" }}>
            <a href="/marketnews/subscribe">Subscribe</a> to be informed of
            new webinars/seminars coming soon!
          </td>
        </tr>
      );
    }
    return (
      <div id="seminar" className="holder-seminar section">
        <div className="page-header">
          <h2 className="small">Seminar</h2>
        </div>
        <p>
          The best way to improve your knowledge of warrants. Sign up for a free
          seminar!
        </p>
        <div
          className="seminar-table-holder"
          style={{ height: "132px", overflowX: "hidden", overflowY: "auto" }}
        // style={{ height: "132px" }}
        >
          <table className="table table-striped" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th width="15%">Date</th>
                <th width="25%">Time</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
        <div className="section-btn-wapper">
          <a
            href="/education/seminar"
            type="button"
            className="btn btn-primary"
            target="_blank"
            style={{ width: "190px" }}
          >
            View more
          </a>
        </div>
      </div>
    );
  }
}

class ToolColumns extends Component {
  render() {
    return (
      <div className="holder-warranttools">
        <div id="warranttools" className="section">
          <div>
            <div className="tools-row">
              <div id="selector-tools" className="tools-detail">
                <WarrantSelector page="home" />
              </div>
            </div>
            {/* <div className="tools-row" style={{ marginTop: "40px" }}>
              <div id="selector-tools" className="tools-detail">
                <LiveMatrix page="home" />
              </div>
            </div>
            <div className="tools-row" style={{ marginTop: "40px" }}>
              <div id="selector-tools" className="tools-detail">
                <WarrantSearch page="home" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

class Fb extends Component {
  render() {
    return (
      <div
        id="facebook-iframe"
        className="section"
        style={{ padding: "40px 10px", textAlign: "center", display: "flex" }}
      >
        <div style={{ width: "70px", paddingRight: 5 }}>
          <img src="/img/home/fb.png" alt="" style={{ width: "100%" }} />
        </div>
        <div style={{ flex: 1 }}>
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMacquarieWarrantsSG%2F&tabs=timeline&width=450&height=375&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            frameBorder="0"
            scrolling="no"
            allowtransparency="true"
            allow="encrypted-media"
            height="370"
            className="fbIframe  hidden-xs"
            style={{ border: "none", overflow: "hidden", width: "100%" }}
          ></iframe>
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMacquarieWarrantsSG%2F&tabs=timeline&width=350&height=375&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            frameBorder="0"
            scrolling="no"
            allowtransparency="true"
            allow="encrypted-media"
            height="370"
            className="fbIframe visible-xs"
            style={{ border: "none", overflow: "hidden", width: "100%" }}
          ></iframe>
        </div>
      </div>
    );
  }
}
export default WarrantTools;
export { Seminar };

// Announcement : require new API from nodejs
