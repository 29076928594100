import React, { Component } from "react";
import AdvertBox from "../../../components/advert/advert";
import MQSGUtil from '../../../components/mqsgUtil';
import "./faq.css";

export default class FAQ extends Component {
  render() {
    return (
      <div id="faq" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <a href="/home/edu">Warrant education</a>
              </li>
              <li className="active">FAQ</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 hidden-sm hidden-xs">
              <img
                style={{ width: "100%" }}
                src="/img/education/u18433.jpg"
                alt=""
              />
            </div>
            <div className="col-md-8">
              <FAQSeaction />
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <AdvertBox />
              <Seminar />
              <Videos />
              <InvestorAcademy />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const questionList = [
  "What is a structured warrant?",
  "Are structured warrants the same as company warrants?",
  "What is a market-maker?",
  "Can I short-sell a warrant?",
  "The underlying has moved, so why hasn’t the warrant moved accordingly?",
  "Why is my broker’s platform or other platforms showing a different warrant price change from your website?",
  "Why are some warrants quoted on wide spreads?",
  "What is the “Live price matrix”?",
  "Where can I see the index futures that some of the index warrants track?",
  "How do I start trading warrants?",
  "What happens if I hold a warrant until expiry?",
  "Are warrants a ‘win-lose’ game? Does the issuer always profit when an investor loses, and vice versa?",
  "Where can I find out more info about warrants?",
  "Your website data does not refresh, what shld I do?",
  "Do I receive ordinary dividends for warrants over a stock that is paying out ordinary dividends?",
  "What about special dividends or other corporate actions such as rights issues? Will I be entitled to them as a warrant holder?",
];
class FAQSeaction extends Component {
  componentDidMount() {
    document.title = "FAQ | Singapore Warrants | Macquarie"
  }
  render() {
    return (
      <div className="section" id="page-headers">
        <div id="page-header" className="page-header">
          <h2>FAQ</h2>
        </div>
        <div id="question-header" className="question-header color-primary">
          Questions you may have
        </div>
        <div className="question-list">
          {questionList.map((item, index) => {
            return (
              <div key={index}>
                <a href={"#section" + (index + 1)}>
                  <span>{item}</span>
                  <img src="/img/bullet/qlink-achor.png" alt="" />
                </a>
              </div>
            );
          })}
          <div>
            <a href="/education/ShortFormVideos" target="_blank">
              <span>Frequently Asked Questions video playlist</span>
              <img src="/img/bullet/qlink-achor.png" alt="" />
            </a>
          </div>
        </div>
        <div id="section1" className="pointer_tiger" style={{ marginTop: '0px', position: 'relative' }}></div>
        <div className="answer-area">
          <div className="content-question">What is a structured warrant?</div>
          <p>
            A Structured Warrant (SW) is an exchange-traded derivative that
            gives the holder the right but not the obligation to buy or sell the
            specific underlying asset at an agreed price (exercise price) on the
            expiry date.
          </p>
          <ul>
            <li>
              A call warrant gives investors the right, not the obligation to
              buy the underlying asset. Typically, the price of a call warrant
              increases as the underlying asset price goes up.
              <br />
              <br />
            </li>
            <li>
              A put warrant gives investors the right, not the obligation to
              sell an underlying. Typically, the price of a put warrant
              increases as the underlying asset price goes down.
            </li>
          </ul>
          <p>
            Structured warrants are available over a range of assets, including
            shares and share indices.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section2" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            Are structured warrants the same as company warrants?
          </div>

          <p>
            Company warrants are call warrants issued by companies (with their
            own stock as the underlying) for the purpose of raising capital.
            Companies are not required to appoint market makers for the warrants
            they issue over their own stock. This type of warrants is considered
            to be suitable for long-term investment and are generally held until
            expiry by investors.
            <br />
            <br />
            Structured warrants are instruments issued by third-party financial
            institutions (issuers). Under SGX Listing Rules, issuers must
            appoint market makers for the warrants they issue. Structured
            warrants listed on the SGX are designed as a trading tool and are
            usually not to be held until expiry.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section3" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">What is a market-maker? </div>

          <p>
            The role of market makers is to provide continuous buy and sell
            quotes in the warrants they are designated for. In doing so, the
            market makers aim to provide a liquid market for the warrants so
            that investors can buy and sell the warrants without difficulty
            during normal trading hours.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section4" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">Can I short-sell a warrant?</div>
          <p>
            Investors can sell and then buy back warrants within the day.
            However, when warrant investors fail to cover the short-sale by the
            end of the trading day and are unable to fulfil their delivery
            obligations, they will be subject to the buying-in by the CDP on
            settlement date. Warrant investors who are subject to the buying-in
            by the CDP can call the Macquarie toll-free hotline 1800-288-2880 to
            request for a quote in the buy-in market.
            <br />
            <br />
            Warrant investors who are bearish or who wish to protect themselves
            against a fall in the price of the underlying may consider buying
            put warrants instead.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section5" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            The underlying has moved, so why hasn’t the warrant moved
            accordingly?{" "}
          </div>
          <p>
            Besides the underlying price, several other factors could also
            affect the movement of warrant price. Typically, when the underlying
            price moves and the warrant price does not move, it could be due to:
          </p>
          <ul>
            <li>
              <a target="_blank" href="/WarrantsGuidebook/delta">
                Low delta
              </a>{" "}
              — when a warrant is deep “out-of-the-money”, the warrant has a low
              delta which means a large movement in the underlying price is
              required to change the warrant price.
              <br />
              <br />
            </li>
            <li>
              <a target="_blank" href="/WarrantsGuidebook/timedecay">
                Time decay
              </a>{" "}
              — the warrant value will decrease as the warrant approaches
              maturity. The decay in time value may offset some or all of the
              gains caused by movement of the underlying price. <br />
              <br />
            </li>
            <li>
              <a target="_blank" href="/WarrantsGuidebook/impliedvolatility">
                Implied volatility
              </a>{" "}
              – a drop in implied volatility will decrease the warrant price; a
              rise in implied volatility will increase the warrant price.
              <br />
              <br />
            </li>
            <li>
              <a target="_blank" href="/WarrantsGuidebook/exerciseratio">
                Warrants per share
              </a>{" "}
              — a warrant with a higher warrants per share figure tends to be
              less sensitive to the underlying price move as compared to a
              similar warrant with a low warrants per share.
            </li>
          </ul>
          <p>
            Click on the above terms for a more detailed understanding on them.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section6" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            Why is my broker’s platform or other platforms showing a different
            warrant price change from your website?{" "}
          </div>
          <p>
            The most accurate way to track warrant price change is to compare
            bid prices over a period of time. All the price changes quoted by
            Macquarie follow this convention. Other brokers and information
            providers may publish the “daily price change” by comparing the last
            traded price with the previous closing price.
            <br />
            <br />
            Unlike shares and stock indices which are usually frequently traded,
            the last trading price of a warrant may have happened days or weeks
            ago and therefore differ significantly from the current value of the
            warrant. In such cases, the last traded price of a warrant may not
            reflect the current best bid and offer price of the warrant. Hence,
            the most accurate way of measuring warrant price changes is to
            compare the change in bid prices over a period of time.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section7" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            Why are some warrants quoted on wide spreads?
          </div>
          <p>
            The market maker generally endeavours to keep tight spreads on
            warrants. However, there are some instances where market makers
            will/may widen spreads.
          </p>
          <ul>
            <li>
              The warrant is deeply “in-the-money” or “out-of-the-money”, with
              very high or low delta respectively. The directional risk
              associated with such warrant is very high. A widened spread is a
              signal from the market makers to investors to take caution when
              trading in such risky warrants.
              <br />
              <br />
            </li>
            <li>
              The inventory of the warrant is running low and the issuer may not
              be able to issue more of the warrants to sell to investors.
              <br />
              <br />
            </li>
            <li>
              If the issuer has sold a significant amount of warrants in a
              particular underlying asset, resulting in an overly large risk
              position.
            </li>
          </ul>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section8" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            What is the “Live price matrix”?{" "}
          </div>
          <p>
            The live matrix is a direct feed from Macquarie’s market making
            system, showing investors exactly where the market maker’s bid and
            offer quotes will be in the warrant for various levels in the
            underlying price. Currently, Macquarie is the only issuer in
            Singapore who offers this service.
            <br />
            <br />
            The live matrix is a very useful tool as it allows investors to see
            how the warrant price will move in line with the underlying price
            movement and whether Macquarie is maintaining a tight spread in our
            bid and offer quotes.
          </p>

          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section9" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            Where can I see the index futures that some of the index warrants
            track?{" "}
          </div>
          <p>
            The “Live index futures” section on the home page of Macquarie’s
            warrant site is one of the few financial websites providing free
            live pricing on the index futures that our index warrants follow.
            There are also intraday and historical charts on these futures
            indices on our site.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section10" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            How do I start trading warrants?
          </div>
          <p>
            Investors can trade the warrants listed on the SGX in the same way
            that they trade shares via a{" "}
            <a target="_blank" href="/education/getstarted">
              Singapore broker
            </a>
            . They can buy and sell the warrants anytime up until the last
            trading day of the warrant, which is 5 trading days before its
            expiry date.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section11" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            What happens if I hold a warrant until expiry?{" "}
          </div>
          <p>
            All structured warrants in Singapore are currently cash settled
            Investors do not take delivery of the underlying asset. Instead,
            holders of warrants with positive value at expiry will receive the
            settlement amount via a cheque sent to their registered CDP address
            within 10 business days from the expiry date. Issuers will
            automatically exercise the warrant if it has any remaining value at
            expiry.
            <br />
            <br />
            The settlement price of the warrants is determined by the settlement
            levels of their underlyings.
          </p>
          <ul>
            <li>
              For stock warrants, it is the average closing price of the
              underlying shares for each of the 5 trading days prior to the
              expiry date
              <br />
              <br />
            </li>
            <li>
              For index warrants, it is the final settlement price of the index
              futures on the expiry date. If the underlying is a foreign index,
              the settlement amount will be converted back to SGD using a FX
              rate taken on the expiry day of the warrant.
            </li>
          </ul>
          <p>
            For the exact settlement formulas, please see{" "}
            <a
              target="_blank"
              style={{ wordBreak: "break-all" }}
              href="/WarrantsGuidebook/cashsettlementamountforsinglesharewarrantsatexpiry"
            >
              https://www.warrants.com.sg/WarrantsGuidebook/cashsettlementamountforsinglesharewarrantsatexpiry
            </a>{" "}
            for call warrants and{" "}
            <a
              style={{ wordBreak: "break-all" }}
              target="_blank"
              href="/WarrantsGuidebook/formulatocalculatevalueofputwarrantatexpiry"
            >
              https://www.warrants.com.sg/WarrantsGuidebook/formulatocalculatevalueofputwarrantatexpiry
            </a>{" "}
            for put warrants.
            <br />
            <br />
            If the warrant expires with zero value, investors need not do
            anything.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section12" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            Are warrants a ‘win-lose’ game? Does the issuer always profit when
            an investor loses, and vice versa?{" "}
          </div>
          <p>
            Trading warrants is not a ‘zero sum game’. The aim of issuers is to
            capture profit by managing risks in the warrants sold. Upon selling
            warrants, issuers will normally buy or sell shares or other assets
            to ‘hedge' their position and attempt to capture a ‘margin’ whether
            warrant prices go up or down.
            <br />
            <br />
            For example, when an issuer sells a call warrant they will usually
            buy the underlying shares to hedge themselves. Thus, if the share
            price increases and investors profit on their call warrants, issuers
            will also gain on their shareholding.
            <br />
            <br />
            Macquarie has been an issuer in the Singapore market for more than
            10 years and is committed to developing and growing the Singapore
            warrants market.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section13" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            Where can I find out more info about warrants?{" "}
          </div>
          <p>
            For detailed explanations on various warrant topics, you can click
            into our{" "}
            <a target="_blank" href="/home/edu">
              Warrant Education
            </a>{" "}
            section. We also have free{" "}
            <a target="_blank" href="/education/seminar">
              education seminars
            </a>{" "}
            conducted by our market makers.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section14" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            Your website data does not refresh, what shld I do?
          </div>
          <p>Please press Ctrl + F5 to refresh the page.</p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section15" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            Do I receive ordinary dividends for warrants over a stock that is
            paying out ordinary dividends?
          </div>
          <p>
            No, warrant holders do not receive ordinary dividends declared by
            the underlying company. However, this does not mean warrant holders
            are disadvantaged as expected ordinary dividends with ex date
            expected to occur between the warrant launch date and the warrant
            expiry date have already been included in the pricing of the warrant
            at the time when the warrant is launched (using a forecast which
            takes into account historical dividend payments, company
            announcements and market expectations).
            <br />
            <br />
            Typically, companies will see their share prices fall by the
            dividend amount on the ex-dividend date . Since warrants track the
            movement in underlying shares, some may assume that the fall in
            share price would mean the price of the call warrants over the
            underlying should also fall whilst the price of put warrants would
            increase.
            <br />
            <br />
            In this case, assuming all other factors being equal, there would be
            no change to the price of the call or put warrants if the price of
            the underlying shares falls by the dividend amount on the
            ex-dividend date. This is because the dividends had already been
            priced into the warrant upfront.
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <div id="section16" className="pointer_tiger"></div>
        <div className="answer-area">
          <div className="content-question">
            What about special dividends or other corporate actions such as
            rights issues? Will I be entitled to them as a warrant holder?
          </div>
          <p>
            Corporate actions other than ordinary dividends such as special
            dividends, share splits and rights issues etc, are not priced into
            the warrant at warrant launch and are adjusted for by making changes
            to the warrant terms on the ex-date. For example, on the ex-date for
            a special dividend, the exercise prices and the number of warrants
            per share will be lowered, so that the impact of the corporate
            action is passed on to the warrant holders and call warrant holders
            are not disadvantaged by the corporate action.
            <br />
            <br />
            The new, lower exercise price and number of warrants per share are
            adjusted/multiplied by an adjustment formula.
            <br />
            <br />
            Assuming the corporate action only relates to dividend, the
            adjustment formula/factor for{" "}
            <span style={{ fontWeight: "bold" }}>special dividends</span> is as
            follows,
          </p>
          <p>
            Adjustment Factor =<br />
            <span style={{ borderBottom: "1px solid #000000" }}>
              &nbsp;(P – SD – FD)&nbsp;
            </span>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;(P –
            FD)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;, where
          </p>
          <p>
            P: Cum-dividend closing price of the underlying share on the last
            trading date before ex-dividend
            <br />
            SD: Special dividend per share
            <br />
            FD: Ordinary dividend per share that goes ex on the same day as the
            special dividend
            <br />
            Adjusted Exercise Price = Old Exercise Price x Adjustment Factor
            <br />
            Adjusted Number of Warrants Per Share = Old Number of Warrants Per
            Share x Adjustment Factor
            <br />
            <br />
          </p>
          <a href="#page-headers">
            <img src="/img/bullet/qlink-achor-sm.png" alt="" />
          </a>
        </div>
        <hr className="hr-hmgbm" />
      </div>
    );
  }
}

class Seminar extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Seminar</h2>
        </div>
        <p>
          The best way to improve your knowledge of warrants. Sign up for a free
          seminar!
        </p>
        <div className="section-qlink-img section-qlink-seminar">
          <a href="/education/seminar" className="section-qlink-achor">
            <img src="/img/bullet/qlink-achor.png" />
          </a>
        </div>
      </div>
    );
  }
}

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: null,
    };
  }
  getVideoApiLink() {
    const url = MQSGUtil.getAPIBasePath() + "/VideoWarrantsJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ link: `/education/marketcommentary/${result[0].title}/${result[0].videos[0].playlist_id}/${result[0].videos[0].index}` })
        },
      );
  }
  componentDidMount() {
    this.getVideoApiLink()
  }
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">School of Warrants</h2>
        </div>
        <p className="section-p">
          Improve your knowledge of warrant in these short 3 min videos.
        </p>
        <div className="section-qlink-img section-qlink-dwvideo">
          <a href={this.state.link} target="_blank" className="section-qlink-achor">
            <img src="/img/bullet/qlink-achor.png" />
          </a>
        </div>
      </div>
    );
  }
}

class InvestorAcademy extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrants guidebook</h2>
        </div>
        <p className="section-p">Learn all about warrants here.</p>
        <div className="section-qlink-img section-qlink-investoracademy">
          <a
            target="_blank"
            href="/WarrantsGuidebook"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" />
          </a>
        </div>
      </div>
    );
  }
}
