import React, { Component } from "react";
import { Link } from "react-router-dom";
import MQSGUtil from "../components/mqsgUtil";


class DWList extends Component {
  getRows() {
    const rows = [];
    const lastviewdata = this.props.lastviewdata;
    if (lastviewdata !== null) {
      if ("dwList" in lastviewdata && Array.isArray(lastviewdata.dwList)) {
        const dwList = lastviewdata.dwList;
        if (dwList.length === 0) {
          const row = (
            <tr className="emptymsg">
              <td colSpan="4">No last view</td>
            </tr>
          );
          rows.push(row);
        } else {
          dwList.forEach((warrant, index) => {
            const ticker = MQSGUtil.getData(warrant, "ticker");
            const bid = MQSGUtil.getData(warrant, "BID");
            const effectivegearing = MQSGUtil.getData(
              warrant,
              "effective_gearing"
            );
            const key = "dwList_" + index + "_" + ticker;
            const column1 = (
              <td className="col_tkr" style={{ background: "#fff" }}>
                <Link to={"/tools/livematrix/" + ticker} target="_blank">
                  {ticker}
                </Link>
              </td>
            );
            const column2 = (
              <td
                className="col_bd"
                style={{ textAlign: "center", background: "#fff" }}
              >
                {bid}
              </td>
            );
            const column3 = (
              <td
                className="col_eg"
                style={{ textAlign: "center", background: "#fff" }}
              >
                {effectivegearing}
              </td>
            );

            const row = (
              <tr className="rowtmpl" key={key}>
                {column1}
                {column2}
                {column3}
              </tr>
            );
            rows.push(row);
          });
        }
      }
    }
    return rows;
  }

  render() {
    const rows = this.getRows();
    return (
      <>
        <div className="page-header">
          <h2 className="small">Related warrants</h2>
        </div>
        <div id="relatedDWTbl">
          <table className="table table-striped table-closed">
            <thead>
              <tr>
                <th>&#8203;&#8203;Warrant code</th>
                <th>Bid (SGD)</th>
                <th>Eff. gearing (x)</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </>
    );
  }
}

class LVList extends Component {
  getRows() {
    const rows = [];
    const lastviewdata = this.props.lastviewdata;
    if (lastviewdata !== null) {
      if ("lvList" in lastviewdata && Array.isArray(lastviewdata.lvList)) {
        const lvList = lastviewdata.lvList;
        if (lvList.length === 0) {
          const row = (
            <tr className="emptymsg" key="noData">
              <td colSpan="4">No last view</td>
            </tr>
          );
          rows.push(row);
        } else {
          lvList.forEach((warrant, index) => {
            const ticker = MQSGUtil.getData(warrant, "ticker");
            const bid = MQSGUtil.getData(warrant, "BID");
            const effectivegearing = MQSGUtil.getData(
              warrant,
              "effective_gearing"
            );
            const key = "lvList_" + index + "_" + ticker;

            const column1 = (
              <td className="col_tkr">
                <Link target="_blank" to={"/tools/livematrix/" + ticker}>
                  {ticker}
                </Link>
              </td>
            );
            const column2 = (
              <td className="col_bd" style={{ textAlign: "center" }}>
                {bid}
              </td>
            );
            const column3 = (
              <td className="col_eg" style={{ textAlign: "center" }}>
                {effectivegearing}
              </td>
            );

            const row = (
              <tr className="rowtmpl" key={key}>
                {column1}
                {column2}
                {column3}
              </tr>
            );
            rows.push(row);
          });
        }
      }
    }
    return rows;
  }

  render() {
    const rows = this.getRows();
    return (
      <>
        <div className="page-header">
          <h2 className="small">Last viewed</h2>
        </div>
        <div id="lastViewTblw">
          <table className="table table-striped table-closed">
            <thead>
              <tr>
                <th>&#8203;&#8203;Warrant code</th>
                <th>Bid (SGD)</th>
                <th>Eff. gearing (x)</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </>
    );
  }
}

export default class LastView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastviewdata: null,
      isLoading: false,
    };
    this.lastQueryRic = null;
  }

  componentDidMount() {
    this.onFetchData(this.props.lastviewrics);
  }

  onFetchData(ric) {
    if (ric !== null) {
      if (this.lastQueryRic !== ric) {
        this.lastQueryRic = ric;
        const isLoading = false;
        let lastviewdata = null;
        const url =
          MQSGUtil.getAPIBasePath() +
          "/MarketDataJSON?type=lastview&ric=" +
          ric;
        fetch(url)
          .then((res) => res.json())
          .then(
            (result) => {
              lastviewdata = result;
              this.setState({ lastviewdata, isLoading });
            },
            (error) => {
              this.setState({ lastviewdata: null, isLoading });
            }
          );
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.lastQueryRic !== this.props.lastviewrics) {
      this.onFetchData(nextProps.lastviewrics);
    }
    return true;
  }

  render() {
    return (
      <div id="lastview" className="section qlink-section">
        <DWList lastviewdata={this.state.lastviewdata} />
        <LVList lastviewdata={this.state.lastviewdata} />
      </div>
    );
  }
}
